require('./bootstrap');
window.toastr = require('toastr/toastr');

window.Chart = require('chart.js/dist/Chart.min');
window.multiSelect = require("multiselect/js/jquery.multi-select");

require('select2/dist/js/select2');

window.dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
};


$(document).ready(function(){
    var origin = window.location.origin;
    var pathname = window.location.pathname;
    const url = origin+'/'+pathname.split('/')[1];

    $('ul.nav-sidebar a').filter(function() {
        return this.href == url;
    }).parent().addClass('active');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).parentsUntil(".sidebar-menu > .nav-treeview").addClass('menu-open');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).addClass('active');

    $('li.has-treeview a').filter(function() {
        return this.href == url;
    }).addClass('active');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).parentsUntil(".sidebar-menu > .nav-treeview").children(0).addClass('active');
});
